import React, { useState } from 'react';
import Button from '../../Common/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Formik } from 'formik';
import Input from '../../Common/Input';
import * as yup from 'yup';
import { IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const validateEmailLoginForm = yup.object().shape({
    email: yup.string().required('Email or number is required'),
    password: yup.string().required('Password is required'),
});

const validateOtpLoginForm = yup.object().shape({
    mobile: yup
        .string()
        .required('Mobile number is required')
        .matches(/^[0-9]{10}$/, 'Mobile number must be 10 digits'),
    otp: yup
        .string()
        .required('OTP is required')
        .matches(/^[0-9]{4}$/, 'OTP must be 4 digits'),
});

const LoginFormComponent = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const [isOtpLogin, setIsOtpLogin] = useState(false); // Toggle between login methods
    const [otpSent, setOtpSent] = useState(false); // OTP sent state

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const { submit, sendOtp, verifyOtp, loading } = props;

    const handleToggleLoginMethod = () => {
        setIsOtpLogin(!isOtpLogin);
    };

    const handleSendOtp = (mobile) => {
        sendOtp(mobile);
        setOtpSent(true);
    };

    const initialEmailValues = {
        email: '',
        password: '',
    };

    const initialOtpValues = {
        mobile: '',
        otp: '',
    };

    const onFormSubmit = async (values) => {
        if (isOtpLogin) {
            verifyOtp({ mobile: values.mobile, otp: values.otp });
        } else {
            submit({ email: values.email, password: values.password });
        }
    };

    return (
        <>
            <Container maxWidth="xs">
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        height: { xs: '10vh', sm: '20vh', md: '100vh', lg: '100vh' },
                        width: 'auto',
                        mx: 4,
                    }}
                    spacing={2}
                >
                    <Typography
                        variant="h5"
                        align="center"
                        sx={{ fontWeight: '600', textTransform: 'uppercase', fontSize: '28px' }}
                    >
                        {isOtpLogin ? 'Login via OTP' : 'Login'}
                    </Typography>

                    <Typography variant="subtitle2" align="center" sx={{ fontSize: '15px', color: '#7D7D7D' }}>
                        {isOtpLogin ? 'Enter your mobile number and OTP' : 'Welcome Back'}
                    </Typography>

                    {isOtpLogin ? (
                        <Formik
                            initialValues={initialOtpValues}
                            onSubmit={(values) => onFormSubmit(values)}
                            validationSchema={validateOtpLoginForm}
                        >
                            {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
                                <>
                                    <Input
                                        type="text"
                                        fullWidth
                                        placeholder="Enter your mobile number"
                                        id="mobile"
                                        name="mobile"
                                        variant="outlined"
                                        onChange={handleChange('mobile')}
                                        onBlur={handleBlur('mobile')}
                                        value={values.mobile}
                                        error={touched.mobile && Boolean(errors.mobile)}
                                        helperText={touched.mobile && errors.mobile}
                                    />

                                    {otpSent && (
                                        <Input
                                            type="text"
                                            fullWidth
                                            placeholder="Enter OTP"
                                            id="otp"
                                            name="otp"
                                            variant="outlined"
                                            onChange={handleChange('otp')}
                                            onBlur={handleBlur('otp')}
                                            value={values.otp}
                                            error={touched.otp && Boolean(errors.otp)}
                                            helperText={touched.otp && errors.otp}
                                        />
                                    )}

                                    {!otpSent && (
                                        <Button
                                            onClick={() => handleSendOtp(values.mobile)}
                                            loading={loading}
                                            fullWidth
                                        >
                                            Send OTP
                                        </Button>
                                    )}

                                    {otpSent && (
                                        <Button onClick={handleSubmit} loading={loading} fullWidth>
                                            Verify OTP
                                        </Button>
                                    )}
                                </>
                            )}
                        </Formik>
                    ) : (
                        <Formik
                            initialValues={initialEmailValues}
                            onSubmit={(values) => onFormSubmit(values)}
                            validationSchema={validateEmailLoginForm}
                        >
                            {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
                                <>
                                    <Input
                                        type="text"
                                        fullWidth
                                        placeholder="Enter your email or number"
                                        id="email"
                                        name="email"
                                        variant="outlined"
                                        onChange={handleChange('email')}
                                        onBlur={handleBlur('email')}
                                        value={values.email}
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={touched.email && errors.email}
                                    />

                                    <Input
                                        type={showPassword ? 'text' : 'password'}
                                        fullWidth
                                        placeholder="Enter password"
                                        id="password"
                                        name="password"
                                        variant="outlined"
                                        onChange={handleChange('password')}
                                        onBlur={handleBlur('password')}
                                        value={values.password}
                                        error={touched.password && Boolean(errors.password)}
                                        helperText={touched.password && errors.password}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />

                                    <Button onClick={handleSubmit} loading={loading} fullWidth>
                                        Login via Password
                                    </Button>
                                </>
                            )}
                        </Formik>
                    )}

                    <Button
                        onClick={handleToggleLoginMethod}
                        fullWidth
                        sx={{
                            color: '#ffffff',
                            backgroundColor: 'orange',
                            '&:hover': {
                                backgroundColor: 'orange',
                            },
                        }}
                    >
                        {isOtpLogin ? 'Login via Password' : 'Login via OTP (First Time Only)'}
                    </Button>
                </Stack>
            </Container>
        </>
    );
};

export default LoginFormComponent;
