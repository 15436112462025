import {
    Box,
    Divider,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { set_dark_mode } from '../../store/common/commonSlice';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { SingleLevelMenu } from './SingleLevelMenu';
import { Fragment } from 'react';
import { MultiLevelMenu } from './MultiLevelMenu';
import { expandDrawerWidth } from '../../helpers/constants';
import { isAuth } from '../../helpers/cookies';
import { FormatTextdirectionLToR } from '@mui/icons-material';

const MenuList = ({ drawerOpen, modules, roles, navigate, path_url, theme, opened, drawerToggle }) => {
    const dispatch = useDispatch();

    console.log(drawerOpen, 'drawerOpen');

    return (
        <>
            <List sx={{ padding: 0 }}>
                <Tooltip placement="right" title="Dark Mode">
                    <ListItemButton
                        button={'true'}
                        key={'Dark Mode'}
                        onClick={() => {
                            dispatch(set_dark_mode());
                            // window.location.reload();
                        }}
                        sx={{
                            my: 0.5,
                            width: expandDrawerWidth,
                            gap: '10px',
                            borderRadius: '10px',
                        }}
                    >
                        <ListItemIcon sx={{ minWidth: '35px' }}>
                            <Brightness4Icon />
                        </ListItemIcon>
                        <ListItemText primary={'Dark Mode'} />
                    </ListItemButton>
                </Tooltip>
            </List>
            <Divider />
            <List>
                {modules.map((module, i) => {
                    let hasAccess = module.role.includes(roles);
                    if (!hasAccess) return false;
                    return (
                        <Fragment>
                            {module.items && module.items.length > 0 ? (
                                <MultiLevelMenu
                                    key={('module_', i)}
                                    module={module}
                                    navigate={navigate}
                                    path_url={path_url}
                                    theme={theme}
                                    opened={opened}
                                    drawerToggle={drawerToggle}
                                />
                            ) : (
                                <SingleLevelMenu
                                    key={('module_', i)}
                                    module={module}
                                    onClick={() => navigate(module.link)}
                                    path_url={path_url}
                                    theme={theme}
                                    drawerToggle={drawerToggle}
                                />
                            )}
                        </Fragment>
                    );
                })}
            </List>
            <Divider />
            <Box sx={{ textAlign: 'center', padding: '20px 0' }}>
                <Typography variant="h6">Our Information</Typography>

                <List className="footer-quick-links address-link" sx={{ paddingLeft: 0 }}>
                    <ListItem>
                        <LocationOnIcon sx={{ marginRight: '8px' }} />
                        <Link href="/" sx={{ textDecoration: 'none' }}>
                            Mumbai, 401105
                        </Link>
                    </ListItem>
                    <ListItem>
                        <EmailIcon sx={{ marginRight: '8px' }} />
                        <Link href="mailto:info@agarwalprojects.com" sx={{ textDecoration: 'none' }}>
                            info@agarwalprojects.com
                        </Link>
                    </ListItem>
                    <ListItem>
                        <PhoneIcon sx={{ marginRight: '8px' }} />
                        <Link href="tel:7700968863" sx={{ textDecoration: 'none' }}>
                            7700968863
                        </Link>
                    </ListItem>
                    <ListItem>
                        <PhoneIcon sx={{ marginRight: '8px' }} />
                        <Link href="tel:9321203369" sx={{ textDecoration: 'none' }}>
                            9321203369
                        </Link>
                    </ListItem>
                    <ListItem>
                        <img
                            src="https://api.agarwalprojects.com/public/kutumb-logo.png"
                            width="25"
                            alt="Kutumb"
                            style={{ marginRight: '8px' }}
                        />
                        <Link
                            href="https://kutumb.app/agarwal-projects?ref=LBM9N&screen=id_card_section"
                            target="_blank"
                            rel="noreferrer"
                            sx={{ textDecoration: 'none' }}
                        >
                            Kutumb Profile
                        </Link>
                    </ListItem>
                </List>

                {/* <Typography variant="body2" sx={{ marginTop: 2 }}>
                    Download the Agarwal Project App to get the latest features.
                </Typography>
                <Link
                    href="https://play.google.com/store/apps/details?id=com.agarwalprojects.app.agarwal_projects"
                    target="_parent"
                    sx={{ display: 'inline-block', marginTop: 2, }}
                >
                    <img src="https://agarwalprojects.com/google_play_store.svg" style={{ backgroundColor: "#000", borderRadius: 10 }} width="161" alt="Google Play Store" />
                </Link> */}

                <Box
                    className="social-list"
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginTop: 2,
                    }}
                >
                    <IconButton
                        href="https://www.facebook.com/agarwal.projects.2024?mibextid=ZbWKwL"
                        target="_blank"
                        rel="noreferrer"
                        color="inherit"
                    >
                        <FacebookIcon />
                    </IconButton>
                    <IconButton
                        href="https://www.facebook.com/agarwalprojects/"
                        target="_blank"
                        rel="noreferrer"
                        color="inherit"
                    >
                        <FacebookIcon />
                    </IconButton>
                    <IconButton
                        href="https://www.youtube.com/@AgarwalProjects"
                        target="_blank"
                        rel="noreferrer"
                        color="inherit"
                    >
                        <YouTubeIcon />
                    </IconButton>
                    <IconButton
                        href="https://www.instagram.com/agarwalprojects/"
                        target="_blank"
                        rel="noreferrer"
                        color="inherit"
                    >
                        <InstagramIcon />
                    </IconButton>
                </Box>
            </Box>
        </>
    );
};

export default MenuList;
