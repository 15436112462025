import React from "react";
import { Button, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { SearchRecords } from "../../../components/Common/SearchRecords";
import { useGetLeadsListQuery } from "../../../store/common/commonApis";
import LeadsTable from "../../../components/admin/leads/LeadsTable";
import { debounce } from "../../../helpers/utils";
import { Done } from "@mui/icons-material";
import { set } from "date-fns";

const LeadsContainer = (props) => {
    const { pagination } = props;
    const [type, setType] = React.useState("");
    const [converted, setConverted] = React.useState(null);

    const { isLoading, data: leads, isFetching } = useGetLeadsListQuery({ ...pagination, type, sortBy: -1, sortField: "createdAt", converted });

    const handlePagination = (setting) => {
        props.handlePaginationState(setting);
    }

    const handleSearch = (val) => {
        let keywords = val.toLowerCase();
        handlePagination({
            ...pagination,
            keywords: keywords
        })
    }

    const processChange = debounce((e) => handleSearch(e));

    return (
        <Stack spacing={1}>
            <Paper sx={{ marginBottom: "24px", padding: 1.5 }}>
                <Grid container justifyContent="space-between">
                    <Grid item sx={{ alignSelf: "center" }}>
                        <Typography variant="h6">List of Leads</Typography>
                    </Grid>
                </Grid>
            </Paper>

            <Grid container>
                <Grid item xs={12} textAlign={"right"} pb={2} pt={1} display={"flex"} justifyContent={"space-between"}>
                    <Stack direction="row" spacing={1}>
                        <Button
                            variant={type === "" ? "contained" : "outlined"}
                            color="primary"
                            onClick={() => setType("")}
                            startIcon={type === "" ? <Done /> : ""}
                        >
                            All
                        </Button>
                        <Button
                            variant={type === "Infolive" ? "contained" : "outlined"}
                            color="primary"
                            onClick={() => setType("Infolive")}
                            startIcon={type === "Infolive" ? <Done /> : ""}
                        >
                            Business Directory / Infolive
                        </Button>
                        <Button
                            variant={type === "JobSearch" ? "contained" : "outlined"}
                            color="primary"
                            onClick={() => setType("JobSearch")}
                            startIcon={type === "JobSearch" ? <Done /> : ""}
                        >
                            Job Search
                        </Button>
                        <Button
                            variant={type === "Skill" ? "contained" : "outlined"}
                            color="primary"
                            onClick={() => setType("Skill")}
                            startIcon={type === "Skill" ? <Done /> : ""}
                        >
                            Skill Developments
                        </Button>
                        <Button
                            variant={type === "Candidate" ? "contained" : "outlined"}
                            color="primary"
                            onClick={() => setType("Candidate")}
                            startIcon={type === "Candidate" ? <Done /> : ""}
                        >
                            Candidate Registration
                        </Button>
                        <Button
                            variant={converted ? "contained" : "outlined"}
                            color="primary"
                            onClick={() => {
                                if (converted) {
                                    setConverted(null);
                                    return;
                                }
                                setConverted(true)
                            }}
                            startIcon={converted ? <Done /> : ""}
                        >
                            Converted
                        </Button>
                        <Button
                            variant={converted === false ? "contained" : "outlined"}
                            color="primary"
                            onClick={() => {
                                if (converted === false) {
                                    setConverted(null)
                                    return;
                                }
                                setConverted(false)
                            }}
                            startIcon={converted === false ? <Done /> : ""}
                        >
                            Non Converted
                        </Button>
                    </Stack>
                    <TextField
                        size="small"
                        id="outlined-search"
                        label="Search here..."
                        type="search"
                        sx={{ width: "25%" }}
                        onChange={(e) => processChange(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Paper
                        sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
                        elevation={0}
                    >
                        <LeadsTable fetching={isFetching} loading={isLoading} count={leads?.count || 0} data={leads?.result || []} pagination={pagination} handlePagination={(val) => handlePagination(val)} />
                    </Paper>
                </Grid>
            </Grid>
        </Stack >
    );
}

export default LeadsContainer;