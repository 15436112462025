import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import WelcomeImage from '../../components/public/WelcomeImage.jsx';
import LoginFormComponent from '../../components/public/login/LoginForm.jsx';
import { axios } from '../../helpers/axios';
import { authenticate, signout } from '../../helpers/cookies';
import withNavigate from '../../routes/withNavigate';
import { baseUrl, loginUrl } from '../../helpers/constants.js';

class LoginContainer extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        this.setState({
            loading: true,
        });
        const token = this.props.search.get('token');
        if (token) {
            signout();
            authenticate({ token }, (res) => {
                if (res) {
                    window.location.href = '/profile';
                } else {
                    this.props.navigate('/noroles');
                }
            });
        }
        this.setState({
            loading: false,
        });
    }

    handleSignIn = async (data) => {
        try {
            this.setState({
                loading: true,
            });
            const login_response = await axios.post(loginUrl, data);

            if (login_response?.success) {
                authenticate(login_response.result, (res) => {
                    if (res) {
                        window.location.reload();
                    } else {
                        this.props.navigate('/noroles');
                    }
                });
            }
            this.setState({
                loading: false,
            });
        } catch (error) {
            this.setState({
                loading: false,
            });
        }
    };

    handleSendOtp = (mobile) => {
        try {
            this.setState({
                loading: true,
            });
            axios
                .post(baseUrl + '/auth/send-otp', { mobile })
                .then((res) => {
                    this.setState({
                        loading: false,
                    });
                })
                .catch((err) => {
                    this.setState({
                        loading: false,
                    });
                });
        } catch (error) {
            this.setState({
                loading: false,
            });
        }
    };

    handleVerifyOtp = async (data) => {
        try {
            this.setState({
                loading: true,
            });
            const verify_response = await axios.post(baseUrl + '/auth/verify-otp', data);

            if (verify_response?.success) {
                authenticate(verify_response.result, (res) => {
                    if (res) {
                        window.location.reload();
                    } else {
                        this.props.navigate('/noroles');
                    }
                });
            }
        } catch (error) {
            this.setState({
                loading: false,
            });
        }
    };

    goTOregister = () => {
        this.props.navigate('/register');
    };

    render() {
        const { loading } = this.state;

        return (
            <Box>
                <Grid sx={{ height: '100vh' }} container spacing={0}>
                    <Grid item xs={12} md={7} sm={12} lg={7} style={{ alignContent: 'center' }}>
                        <WelcomeImage />
                    </Grid>
                    <Grid item xs={12} md={5} sm={12} lg={5}>
                        <LoginFormComponent
                            loading={loading}
                            submit={this.handleSignIn}
                            sendOtp={this.handleSendOtp}
                            verifyOtp={this.handleVerifyOtp}
                            goTOregister={this.goTOregister}
                        />
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

export default withNavigate(LoginContainer);
