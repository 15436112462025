import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../../helpers/constants';
import { getCookie } from '../../helpers/cookies';

const commonApis = createApi({
    reducerPath: 'commonApis',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getCookie('token');
            if (token) {
                headers.set('Authorization', `BEARER ${token}`);
            }
            return headers;
        },
    }),
    keepUnusedDataFor: 0,
    tagTypes: ['Leads'],
    endpoints: (build) => ({
        getDashboardCount: build.query({
            query: (params) => ({
                url: '/common/dashboard-count',
                method: 'GET',
                params: params,
            }),
            transformResponse: (response) => response.result,
        }),
        handleImageUpload: build.mutation({
            query: (payload) => ({
                url: '/common/upload-single-image',
                method: 'POST',
                body: payload,
                formData: true,
            }),
            transformResponse: (response) => response.result,
        }),
        handleFileUpload: build.mutation({
            query: (payload) => ({
                url: '/common/upload-single-pdf',
                method: 'POST',
                body: payload,
                formData: true,
            }),
            transformResponse: (response) => response.result,
        }),
        getLeadsList: build.query({
            query: (params) => ({
                url: '/common/leads',
                method: 'GET',
                params: params,
            }),
            transformResponse: (response) => response.result,
            providesTags: ['Leads'],
            keepUnusedDataFor: 5,
        }),
        updateLeads: build.mutation({
            query: (body) => ({
                url: '/common/leads',
                method: 'PUT',
                body: body,
            }),
            transformResponse: (response) => response.result,
            invalidatesTags: ['Leads'],
        }),
        deleteLeads: build.mutation({
            query: (params) => ({
                url: '/common/leads',
                method: 'DELETE',
                params: params,
            }),
            transformResponse: (response) => response.result,
            invalidatesTags: ['Leads'],
        }),
        getContactLeadsList: build.query({
            query: (params) => ({
                url: '/common/contact-us',
                method: 'GET',
                params: params,
            }),
            transformResponse: (response) => response.result,
        }),
    }),
});

export const {
    useGetDashboardCountQuery,
    useHandleImageUploadMutation,
    useHandleFileUploadMutation,
    useGetLeadsListQuery,
    useUpdateLeadsMutation,
    useDeleteLeadsMutation,
    useGetContactLeadsListQuery,
} = commonApis;
export default commonApis;
