export const baseUrl = 'https://api.agarwalprojects.com';
// export const baseUrl = "http://localhost:7055"

export const loginUrl = baseUrl + '/auth/login';

export const drawerWidth = 60;
export const expandDrawerWidth = 260;

export const fileTypes = ['jpeg', 'jpg', 'png', 'gif'];
export const videoTypes = ['mp4'];
