import { Box, Grid, Paper, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';
import React from 'react';
import { CardSummary } from '../../../components/Common/CardSummary';
import { useGetDashboardCountQuery } from '../../../store/common/commonApis';
import DashboardSkeleton from '../../../components/Common/DashboardSkeleton';
import { isAuth } from '../../../helpers/cookies';
import { ProfileForm } from '../../../components/admin/profile/ProfileForm';
import { ChangePasswordForm } from '../../../components/admin/profile/ChangePasswordForm';
import { useGetAuthUserQuery } from '../../../store/user/userApis';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const DashboardContainer = () => {
    const theme = useTheme();
    const userRoles = isAuth().roles;
    const authData = isAuth();
    const { data, isLoading } = useGetDashboardCountQuery();
    const { data: userData } = useGetAuthUserQuery();
    const [currentTab, setCurrentTab] = React.useState(0);

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    return (
        <>
            {isLoading ? (
                <DashboardSkeleton />
            ) : (
                <Stack spacing={1}>
                    <Grid container gap={1}>
                        {userRoles === 'admin' && (
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                <CardSummary title="Total Number of Sanstha" value={data.sanstha} />
                            </Grid>
                        )}
                        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                            <CardSummary title="Total Number of Members" value={data.member} />
                        </Grid>
                        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                            <CardSummary title="Total Number of Candidates" value={data.candidate} />
                        </Grid>
                    </Grid>

                    <Paper sx={{ marginBottom: '24px', padding: 1.5 }}>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={12}>
                                <Tabs
                                    value={currentTab}
                                    onChange={handleChange}
                                    aria-label="wrapped label tabs example"
                                >
                                    <Tab value={0} label="Profile" />
                                    <Tab value={1} label="Change Password" />
                                </Tabs>
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper sx={{ marginBottom: '24px', padding: 1.5 }}>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={12}>
                                <TabPanel value={currentTab} index={0} dir={theme.direction}>
                                    <ProfileForm initialValue={userData} roles={authData?.roles} />
                                </TabPanel>
                                <TabPanel value={currentTab} index={1} dir={theme.direction}>
                                    <ChangePasswordForm
                                        roles={authData?.roles}
                                        user_id={userData?.user_id}
                                        data={userData}
                                    />
                                </TabPanel>
                            </Grid>
                        </Grid>
                    </Paper>
                </Stack>
            )}
        </>
    );
};

export default DashboardContainer;
